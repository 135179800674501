import fetchPonyfill from 'fetch-ponyfill';
const { fetch } = fetchPonyfill();

export default class AuthenticatedApiCaller {
    static baseUrl = '/api';

    static isLocal() {
        return window.location.hostname === 'local.ejzafvtblg.execute-api.us-east-1.amazonaws.com';
    }

    static getUrl(endpoint) {
        if (this.isLocal()) {
            return 'https://ejzafvtblg.execute-api.us-east-1.amazonaws.com/prod/api/' + endpoint;
        }
        return this.baseUrl + '/' + endpoint;
    }

    /**
     *
     * @param {RequestInfo} endpoint
     * @param {RequestInit} options
     */
    static async makeRequest(endpoint, options) {
        if (!options.headers) {
            options.headers = {};
        }
        if (this.isLocal()) {
            options.mode = 'cors';
            options.credentials = 'include'; // Cookies aren't set cross domain by default.
        }

        const url = this.getUrl(endpoint);
        const response = await fetch(url, options);

        if (!response.ok) {
            let errorBody = await response.text();
            try {
                errorBody = JSON.parse(errorBody);
            }
            catch (e) { }
            return { error: errorBody || response.statusText };
        }

        if (response.status === 204) {	// 204 = No Content
            return;
        }

        const responseText = await response.text();

        let data = responseText;

        try {
            data = JSON.parse(responseText);
        } catch (e) { }

        return data;
    }
}