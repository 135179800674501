import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import NewBatch from '../NewBatch';
import SetupQBO from '../SetupQBO';
import TokenRefresh from '../TokenRefresh';
import Login from '../Login';

export default class MainContainer extends Component {

    render() {
        return (
            <Switch>
                <Route exact path='/' render={props => (<NewBatch props={props} />)} />
                <Route path='/setup-qbo' render={props => (<SetupQBO props={props} />)} />
                <Route path='/token-refresh' render={props => (<TokenRefresh props={props} />)} />
                <Route path='/login' render={props => (<Login props={props} />)} />
            </Switch>
        );
    }
}