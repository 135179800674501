import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import Datetime from 'react-datetime';
export default class InvoiceSearch extends Component {
    render() {
        return <Form>
            <Col sm={12}>
                <FormGroup row>
                    <Label for="minDate" sm={4}>Min Invoice Date:</Label>
                    <Col sm={8}>
                        <Datetime id="minDate"
                            onChange={(date) => {
                                this.props.setStateCustom({ minDate: date, unpostedInvoicesQueryResult: null });
                            }}
                            defaultValue={this.props.minDate} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="maxDate" sm={4}>Max Invoice Date:</Label>
                    <Col sm={8}>
                        <Datetime id="maxDate"
                            onChange={(date) => {
                                this.props.setStateCustom({ maxDate: date, unpostedInvoicesQueryResult: null });
                            }}
                            defaultValue={this.props.maxDate} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="maxResults" sm={4}>Page Size:</Label>
                    <Col sm={8}>
                        <Input id="maxResults"
                            className={this.props.maxResults > 25 || this.props.maxResults < 1 ? 'invalid-field' : ''}
                            defaultValue={1}
                            onChange={(e) => { this.props.setStateCustom({ maxResults: e.target.value, unpostedInvoicesQueryResult: null }) }} />
                        <span className="maxResultsValidation invalid-field-text">
                            {(this.props.maxResults > 25 || this.props.maxResults < 1) && <div>*Page size must be between 1 and 25</div>}
                        </span>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="searchCompanyName" sm={4}>Company Name:</Label>
                    <Col sm={8}>
                        <Input id="searchCompanyName"
                            onChange={(e) => { this.props.setStateCustom({ searchCompanyName: e.target.value, unpostedInvoicesQueryResult: null }) }} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="searchAccountNumber" sm={4}>Account ID:</Label>
                    <Col sm={8}>
                        <Input id="searchAccountNumber"
                            onChange={(e) => { this.props.setStateCustom({ searchAccountNumber: e.target.value, unpostedInvoicesQueryResult: null }) }} />
                    </Col>
                </FormGroup>
                {
                    this.props.loadingInvoices
                        ? <div>Searching invoices...</div>
                        : <Button onClick={this.props.searchInvoices} disabled={this.props.maxResults > 25 || this.props.maxResults < 1}>Search Invoices</Button>
                }
            </Col>
        </Form>;
    }
}