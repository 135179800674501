
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';
import ObjectAssignPolyfill from 'es6-object-assign';
import Es6Promise  from 'es6-promise';
ObjectAssignPolyfill.polyfill();
Es6Promise.polyfill();


ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));