import React, { Component } from 'react';
import {
    Button,
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import { Pod, PodHeader, PodBody } from './Common/Pod';
import TokenStore from "../Services/token-store";
import QboSetupService from '../Services/qbo-setup-service';
import WaitSpinner from './Common/WaitSpinner';
import config from '../config.json';
import AuthenticatedApiCaller from '../Services/authenticated-api-caller';

export default class SetupQBO extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isConnected: false,
            realmId: '',
            companyName: '',
            tokenExpires: '',
            showWaitSpinner: false
        };

        this.disconnectClickHandler = this.disconnectClickHandler.bind(this);

    }

    _clientId = AuthenticatedApiCaller.isLocal() ? 'L0quA1z7OwAqrpzGtkEUNokRhhIjq6tCtVaIAWwZrrEePcfHaC' : config.qboClientId;

    getRedirectUri() {
        if (AuthenticatedApiCaller.isLocal()) {
            return 'https://ejzafvtblg.execute-api.us-east-1.amazonaws.com/prod/authorization';
        }
        return `${window.location.origin}/authorization`;
    }

    _redirectUri = encodeURIComponent(this.getRedirectUri());
    linkState = encodeURIComponent(btoa(JSON.stringify({ 'partner_id': !TokenStore.getToken() ? '' : TokenStore.getDecodedToken()['partner_id'] })));
    qboAuthLink = `https://appcenter.intuit.com/connect/oauth2?client_id=${this._clientId}` +
        `&scope=com.intuit.quickbooks.accounting` +
        `&redirect_uri=${this._redirectUri}` +
        `&response_type=code` +
        `&state=${this.linkState}`;

    disconnectClickHandler = async (event) => {
        event.preventDefault();
        await QboSetupService.disconnectQbo();
        this.setState({ isConnected: false, realmId: '', tokenExpires: '', companyName: '' });
    }

    async componentDidMount() {
        this._isMounted = true;

        this.setState({ showWaitSpinner: true });
        const qboSetupData = await QboSetupService.getSetupData();
        if (this._isMounted) {
            this.setState({ ...qboSetupData, showWaitSpinner: false });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (<Container>
            <Pod style={{ marginTop: '10px' }}>
                <PodHeader>Setup QuickBooks Online</PodHeader>
                <PodBody>
                    <Form>
                        <Col sm={12}>
                            <FormGroup row>
                                <Label for="companyName" sm={4}>Company Name</Label>
                                <Col sm={8}>
                                    <Input type="text" name="companyName" id="companyName" value={this.state.companyName} readOnly></Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="realmId" sm={4}>Company ID</Label>
                                <Col sm={8}>
                                    <Input type="text" name="realmId" id="realmId" value={this.state.realmId} readOnly></Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="qboAuth" sm={4}>QuickBooks Online Connection</Label>
                                <Col sm={8}>
                                    {this.state.isConnected ?
                                        (<Button onClick={this.disconnectClickHandler} color='danger'>Disconnect</Button>) :
                                        (
                                            <a href={this.qboAuthLink}>
                                                <img src="/resources/C2QB_white_btn_lg_default.png" alt="Connect to QuickBooks Online" id="qboAuth" style={{ width: '200px' }}></img>
                                            </a>
                                        )
                                    }
                                </Col>
                            </FormGroup>
                        </Col>
                    </Form>
                </PodBody>
            </Pod>
            <WaitSpinner shown={this.state.showWaitSpinner} />
        </Container >
        )
    };
}