import AuthenticatedApiCaller from './authenticated-api-caller';

export default class InvoiceService {

    static async getUnpostedInvoices(data) {
        return await AuthenticatedApiCaller.makeRequest('unpostedinvoices', {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    static async sendUnpostedInvoices(data) {
        return await AuthenticatedApiCaller.makeRequest('unpostedinvoices/send-to-accounting-package', {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    static async syncPayments() {
        return await AuthenticatedApiCaller.makeRequest('payment/syncpayments', {
            method: 'POST',
            body: JSON.stringify({})
        });
    }

    static async getAccountingInvoicePdf(id) {
        return await AuthenticatedApiCaller.makeRequest('accounting/invoice/pdf?accounting-invoice-id=' + id, {
            method: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType: "application/pdf",
        });
    }

    static async getInvoicePdf(invoiceId) {
        return await AuthenticatedApiCaller.makeRequest('invoice/pdf', {
            method: 'POST',
            body: JSON.stringify({
                "invoiceId": invoiceId
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "application/pdf",
        });
    }
}