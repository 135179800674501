import React, { Component } from 'react';
import {
    Container,
    Col,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import { Pod, PodHeader, PodBody } from './Common/Pod';
import TokenStore from "../Services/token-store";

export default class TokenRefresh extends Component {

    async componentDidMount() {
        if (!TokenStore.getToken()) {
            return; // User needs to login successfully or it could be a redirect loop.
        }
        let millisecondsUntilRefresh = TokenStore.getDecodedToken().exp * 1000 - new Date().getTime();
        setTimeout(
            async function() {
                await TokenStore.goToAuthenticationRedirect()
            },
            millisecondsUntilRefresh
        );
    }

    render() {
        return (<Container>
                <Pod style={{ marginTop: '10px' }}>
                    <PodHeader>Token Refresh</PodHeader>
                    <PodBody>
                        <Form>
                            <Col sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>This page is used as an iframe to refresh tokens.</Label>
                                </FormGroup>
                            </Col>
                        </Form>
                    </PodBody>
                </Pod>
            </Container>
        )
    };
}