import AuthenticatedApiCaller from './authenticated-api-caller';

export default class QboSetupService {
    static async getSetupData() {
        return await AuthenticatedApiCaller.makeRequest('qbosetup', {
            method: 'GET'
        });
    }

    static async disconnectQbo() {
        return await AuthenticatedApiCaller.makeRequest('qboToken', {
            method: 'DELETE'
        });
    }
}