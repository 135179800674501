import React, { Component } from 'react';
import './App.css';
import Navigation from './Components/Navigation/Navigation';
import MainContainer from './Components/Navigation/MainContainer';
import TokenStore from './Services/token-store';
import Iframe from 'react-iframe'

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdmin: TokenStore.isAdmin(),
      token: TokenStore.getToken()
    };
  }

  async componentDidMount() {
    const token = TokenStore.getToken();
    if (!token && !window.location.hash.startsWith('#/login?')) {
      window.location.href = '#/login';
    }
  }

  render() {
    return (
      <div className="App">
        <Navigation {...this.state} />
        <MainContainer />
        <div className="hide">
          <Iframe url="/#/token-refresh"
                  width="1px"
                  height="1px"
                  id="tokenRefreshIframe"
                  display="initial"
                  position="relative"/>
        </div>
      </div>
    );
  }
}

export default App;
