import React, { Component } from 'react';
import { Button, Container, Col, Form, Alert } from 'reactstrap';
import { Pod, PodHeader, PodBody } from './Common/Pod';
import TokenStore from '../Services/token-store';

export default class Login extends Component {

    constructor(props) {
        super(props);
        let error = '';
        let paramsStart = window.location.hash.indexOf("?");
        if (paramsStart > -1) {
            error = new URLSearchParams(window.location.hash.substring(paramsStart)).get('failureReason');
        }
        this.state = {
            error: error,
            success: ''
        };
    }

    render() {
        return (
            <Container>
                <Pod style={{ marginTop: '10px' }}>
                    <PodHeader>Login</PodHeader>
                    <PodBody>
                        { this.state.error && <Alert color="danger">{this.state.error}</Alert> }
                        { this.state.success && <Alert color="success">{this.state.success}</Alert> }
                        <Form>
                            <Col sm={12}>
                                <Button onClick={() => TokenStore.goToAuthenticationRedirect('#/')}>Login with ConnectWise SSO</Button>
                            </Col>
                        </Form>
                        <Col style={{marginTop: '12px' }} sm={12}>
                            If you do not have a ConnectWise SSO account,
                            please contact your ConnectWise Admin to invite you.
                            Your ConnectWise Admin can refer to <a target="_blank" rel="noopener noreferrer" href="https://docs.connectwise.com/ConnectWise_Documentation/ConnectWise_Unified_Product/ConnectWise_Home/040#Invite_a_User">ConnectWise Home - User and Application Settings</a> for instructions on inviting a user.
                        </Col>
                    </PodBody>
                </Pod>
            </Container >
        );
    };
}