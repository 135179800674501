import React, { Component } from 'react';
import {
    Button,
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import { Pod, PodHeader, PodBody } from './Common/Pod';
import ErrorPod from './Common/ErrorPod';
import moment from 'moment';
import '../Css/react-datetime.css';
import '../Css/new-batch.css';
import InvoiceService from '../Services/invoice-service';
import InvoiceSearch from './NewBatch/InvoiceSearch';
import WaitSpinner from './Common/WaitSpinner';

export default class NewBatch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxResults: 1,
            minDate: moment().subtract(1, 'months').startOf('month'),
            maxDate: moment().endOf("day"),
            searchCompanyName: '',
            searchAccountNumber: '',
            currentSearchPage: 1,
            showWaitSpinner: false,
            batchIdentifier: "qbo-batch-" + moment().format('YYYY-MM-DDTHH:mm:ss'),
            unpostedInvoiceIds: [],
            sendingToQbo: false,
            showSendToQboValidation: false,
            invoiceSummary: 'Detailed'
        };
        this.setStateCustom = this.setStateCustom.bind(this);
        this.searchInvoices = this.searchInvoices.bind(this);
    }

    async sendToQbo(event) {
        event.preventDefault();
        if (this.state.unpostedInvoiceIds.length === 0) {
            this.setState({showSendToQboValidation: true});
            return;
        }
        this.setState({ sendingToQbo: true, showWaitSpinner: true, showSendToQboValidation: false });
        let invoiceResponse;
        try {
             invoiceResponse = await InvoiceService.sendUnpostedInvoices({
                "unpostedInvoiceIds": this.state.unpostedInvoiceIds,
                "batchIdentifier": this.state.batchIdentifier,
                "exportDetailType": this.state.invoiceSummary
            });
        } catch (error) {
            console.log(error);
            alert(error);
            return;
        }
        this.setState({ sendingPaymentsToManage: true });
        try {
            await InvoiceService.syncPayments();
        } catch (paymentEx) {
            console.log(paymentEx);
        }
        this.setState({
            postedInvoicesResult: invoiceResponse,
            sendingToQbo: false,
            sendingPaymentsToManage: false,
            showWaitSpinner: false,
            batchIdentifier: "qbo-batch-" + moment().format('YYYY-MM-DDTHH:mm:ss'),
            unpostedInvoiceIds: []
        });
    }

    setStateCustom(newObject) {
        this.setState(newObject);
    }

    searchInvoices() {
        this.setState({ loadingInvoices: true, postedInvoicesResult: null });
        this.getData();
    }

    formatUsd(number) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
    }

    selectInvoice(unpostedInvoice) {
        let self = this;
        return function (e) {
            let arrayCopy;
            if (e.target.checked) {
                arrayCopy = self.state.unpostedInvoiceIds.slice();
                arrayCopy.push(unpostedInvoice.id);
            } else {
               arrayCopy = self.state.unpostedInvoiceIds.filter(x => x !== unpostedInvoice.id);
            }
            self.setState({unpostedInvoiceIds: arrayCopy});
        }
    }

    async openAccountingInvoice(e) {
        let accountingInvoiceId = e.target.getAttribute('data-accounting-invoice-id');
        let accountingInvoiceNumber = e.target.getAttribute('data-accounting-invoice-number');
        let pdfBase64Url = "data:application/pdf;base64," +
            await InvoiceService.getAccountingInvoicePdf(accountingInvoiceId);
        var win = window.open();
        win.document.write('<title>QuickBooks Online Invoice #' + accountingInvoiceNumber + '</title>');
        win.document.write('<iframe src="' + pdfBase64Url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:98%;" allowfullscreen></iframe>');
    }

    async openInvoice(e) {
        let invoiceId = e.target.getAttribute('data-invoice-id');
        let invoiceNumber = e.target.getAttribute('data-invoice-number');
        let pdfBase64Url = "data:application/pdf;base64," +
            await InvoiceService.getInvoicePdf(invoiceId);
        var win = window.open();
        win.document.write('<title>Manage Invoice #' + invoiceNumber + '</title>');
        win.document.write('<iframe src="' + pdfBase64Url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:98%;" allowfullscreen></iframe>');
    }

    getPayments(qboInvoiceId) {
        let invoicePaymentLines = [];
        if (!this.state.postedInvoicesResult.paymentsCreated) {
            return invoicePaymentLines;
        }
        for (let paymentIndex = 0; paymentIndex < this.state.postedInvoicesResult.paymentsCreated.length; paymentIndex += 1) {
            for (let lineIndex = 0; lineIndex < this.state.postedInvoicesResult.paymentsCreated[paymentIndex].Line.length; lineIndex += 1) {
                let invoiceLine = this.state.postedInvoicesResult.paymentsCreated[paymentIndex].Line[lineIndex];
                if (invoiceLine.LinkedTxn[0].TxnId === qboInvoiceId && // These payments are from Manage and Manage only allows one payment per invoice
                    invoiceLine.LinkedTxn[0].TxnType.toLowerCase() === "invoice") {
                    invoicePaymentLines.push(this.state.postedInvoicesResult.paymentsCreated[paymentIndex]);
                }
            }
        }
        return invoicePaymentLines;
    }

    getSubtotalLine(lines) {
        return lines.find(x => x.DetailType.toLowerCase() === "subtotallinedetail");
    }

    getInvoiceDuplicateReviewWithPdf(invoice) {
        return this.getInvoiceDuplicateReview(invoice, true);
    }

    getInvoiceDuplicateReview(invoice, includePdf) {
        return (<div className="invoice-review" key={invoice.Id}>
            <span
                data-accounting-invoice-id={invoice.Id}
                data-accounting-invoice-number={invoice.DocNumber}
                className={includePdf ? "pseudo-link" : "" }
                onClick={includePdf ? this.openAccountingInvoice : null}
            >{invoice.DocNumber}</span>
        </div>);
    }

    getInvoiceReviewWithPdf(invoice) {
        return this.getInvoiceReview(invoice, true);
    }

    getInvoiceReview(invoice, includePdf) {
        return (<div className="invoice-review" key={invoice.Id}>
            <span
                data-accounting-invoice-id={invoice.Id}
                data-accounting-invoice-number={invoice.DocNumber}
                className={includePdf ? "pseudo-link" : "" }
                onClick={includePdf ? this.openAccountingInvoice : null}
                >{invoice.DocNumber}</span>
            <div className="invoice-totals">
                <div>subtotal {this.formatUsd(this.getSubtotalLine(invoice.Line).Amount)}</div>
                {invoice.TxnTaxDetail && <div>tax {this.formatUsd(invoice.TxnTaxDetail.TotalTax)}</div>}
                <div>
                    total {this.formatUsd(invoice.TotalAmt)}
                    {
                        this.formatUsd(
                            this
                                .state
                                .unpostedInvoicesQueryResult.items.find(x => x.invoiceNumber.toLowerCase() === invoice.DocNumber.toLowerCase())
                                .total) !== this.formatUsd(invoice.TotalAmt) &&
                        <span>
                            &nbsp;-&nbsp;
                            <span className="invoice-total-warning"><strong>warning</strong></span> the posted total doesn't match the total in Manage
                        </span>
                    }
                </div>
                {
                    this.getPayments(invoice.Id).map((payment) => (
                        <div key={payment.Id}>payment {this.formatUsd(payment.Line[0].Amount)}</div>
                    ))
                }
            </div>
        </div>);
    }
    async getData(page) {
        if (!page || page < 1) {
            page = 1;
        }
        let self = this;
        this.setState({ showWaitSpinner: true, unpostedInvoiceIds: [] });
        const unpostedInvoices = await InvoiceService
            .getUnpostedInvoices({
                "page": page,
                "limit": self.state.maxResults,
                "minDate": self.state.minDate.toISOString(),
                "maxDate": self.state.maxDate.toISOString(),
                "companyName": self.state.searchCompanyName,
                "accountNumber": self.state.searchAccountNumber
            });

        
        if (unpostedInvoices) {
            self.setState({
                unpostedInvoicesQueryResult: unpostedInvoices,
                loadingInvoices: false,
                sendingToQbo: false,
                currentSearchPage: page
            });
        }
        this.setState({ showWaitSpinner: false });
    }

    getErrorText(errorResponse)
    {
        const supportMail = <a href='mailto:support@connectwise.com'>Support</a>;
        switch(errorResponse.errorType) {
            case 'MissingKey': 
                return (<div>A Business Suite API Key was not found. 
                        You must review and accept the ConnectWise Business Suite Integration terms of service on the ConnectWise Manage Today page before using this application. 
                        If you continue to experience issues, please contact {supportMail} for assistance.
                    </div>);
            case 'InvalidKey':
                return (<div>Please refresh your Business Suite Keys.  
                        Click <a href='https://docs.connectwise.com/ConnectWise_Support_Wiki/Finance/QuickBooks_Online_Run_New_Batch_gives_a_blank_screen_when_searching_for_invoices' 
                        target='_blank' rel='noreferrer noopener'>here</a> for additional information. 
                        If you continue to experience issues, please contact {supportMail} for assistance. 
                    </div>);
            case 'UserAuth':
                return (<div>{errorResponse.error}. Please <a href='#/login'>Log in</a> again. If you continue to experience issues, please contact {supportMail} for assistance.</div>) 
            default:
                return (<div>An Unknown Error occurred. Please try again. If you continue to experience issues, please contact {supportMail} for assistance.</div>)
       }
    }
    
    render() {
        const hasInvoiceError = this.state.unpostedInvoicesQueryResult && this.state.unpostedInvoicesQueryResult.error;
        const hasInvoices = this.state.unpostedInvoicesQueryResult && this.state.unpostedInvoicesQueryResult.totalCount 
                                && this.state.unpostedInvoicesQueryResult.totalCount > 0;
        return (
            <Container>
                {  !hasInvoiceError ? '' :
                <ErrorPod header="Warning" body={this.getErrorText(this.state.unpostedInvoicesQueryResult.error)}/>
                }
                <Pod style={{ marginTop: '10px' }}>
                    <PodHeader>Search Unposted Invoices</PodHeader>
                    <PodBody>
                        <InvoiceSearch
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            maxResults={this.state.maxResults}
                            loadingInvoices={this.state.loadingInvoices}
                            setStateCustom={this.setStateCustom}
                            searchInvoices={this.searchInvoices}
                        />
                    </PodBody>
                </Pod>
                <Pod style={{ marginTop: '10px' }}>
                    <PodHeader>Select Unposted Invoices</PodHeader>
                    <PodBody>
                        {
                            !this.state.unpostedInvoicesQueryResult ? '' :
                                <div>
                                    {
                                        hasInvoices &&
                                        <div className="table-counts">
                                            <div className="table-paging-decription">Page {this.state.currentSearchPage} of {Math.ceil(this.state.unpostedInvoicesQueryResult.totalCount / this.state.maxResults)}</div>
                                            <div className="table-paging-buttons">
                                                <span
                                                    onClick={this.state.currentSearchPage !== 1
                                                        ? () => this.getData(this.state.currentSearchPage - 1)
                                                        : null}
                                                    className={this.state.currentSearchPage !== 1 ? "pseudo-link" : ""}>&nbsp;previous</span>
                                                &nbsp;|&nbsp;
                                                <span
                                                    onClick={
                                                        this.state.currentSearchPage !== Math.ceil(this.state.unpostedInvoicesQueryResult.totalCount / this.state.maxResults)
                                                            ? () => this.getData(this.state.currentSearchPage + 1)
                                                            : null}
                                                    className={
                                                        this.state.currentSearchPage !== Math.ceil(this.state.unpostedInvoicesQueryResult.totalCount / this.state.maxResults) ? "pseudo-link" : ""}>&nbsp;next</span>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <div className="col-invoice-select"></div>
                                        <div className="col-invoice-date">Invoice Date</div>
                                        <div className="col-header-invoice-number">Invoice Number</div>
                                        <div className="col-header-invoice-company-name">Company Name</div>
                                        <div className="col-header-invoice-account-number">Account ID</div>
                                        <div className="col-header-total">Total</div>
                                    </div>
                                </div>
                        }
                        {
                            !this.state.unpostedInvoicesQueryResult || !hasInvoices ? '' :
                            <div className="list-view">
                            {
                                this.state.unpostedInvoicesQueryResult.items.map((key) => {
                                    return <div
                                        data-invoice-id={key.billingLogId}
                                        key={key.id}
                                        className="list-view-row">
                                        <div className="col-invoice-select">
                                            <input data-unpostedinvoice-id={key.id}
                                                   className={"invoice-selection" + (this.state.showSendToQboValidation && ' invalid-field')}
                                                   type="checkbox"
                                                   onChange={this.selectInvoice(key)}
                                                   checked={this.state.unpostedInvoiceIds.includes(key.id)} />
                                        </div>
                                        <div className="col-invoice-date">{moment(key.invoiceDate).utc().format('MM/DD/YYYY')}</div>
                                        <div className="col-invoice-number">
                                    <span
                                        data-invoice-id={key.billingLogId}
                                        data-invoice-number={key.invoiceNumber}
                                        className="pseudo-link"
                                        onClick={this.openInvoice}>{key.invoiceNumber}</span>
                                        </div>
                                        <div className="col-invoice-company-name">{key.company.name}</div>
                                        <div className="col-invoice-account-number">{key.accountNumber}</div>
                                        <div className="col-total">{this.formatUsd(key.total)}</div>
                                    </div>
                                })
                            }
                            </div>
                        }
                    </PodBody>
                </Pod>
                <Pod style={{ marginTop: '10px' }}>
                    <PodHeader>Configure Batch</PodHeader>
                    <PodBody>
                        <Form>
                            <Col sm={12}>
                                <FormGroup row>
                                    <Label for="batchIdentifier" sm={4}>Batch ID</Label>
                                    <Col sm={8}>
                                        <Input
                                            id="batchIdentifier" type="text"
                                            onChange={ (e) => {
                                                this.setState({batchIdentifier: e.target.value});
                                            }}
                                            value={this.state.batchIdentifier} />{' '}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="invoiceSummary" sm={4}>Invoice Summary</Label>
                                    <Col sm={8}>
                                        <select
                                            id="invoiceSummary" type="text"
                                            onChange={ (e) => {
                                                this.setState({invoiceSummary: e.target.value});
                                            }}
                                            value={this.state.invoiceSummary}>
                                            <option>Default</option>
                                            <option>Condensed</option>
                                            <option defaultValue={this.state.invoiceSummary === 'Detailed'}>Detailed</option>
                                        </select>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Form>
                    </PodBody>
                </Pod>
                <Pod style={{ marginTop: '10px' }}>
                    <PodHeader>Post to QuickBooks Online</PodHeader>
                    <PodBody>
                        <Col>
                        {
                            this.state.sendingPaymentsToManage
                                ? <div>Posting Payments to Manage</div>
                                : this.state.sendingToQbo
                                    ? <div>Posting Invoices to QuickBooks Online</div>
                                    : this.state.postedInvoicesResult
                                        ? ''
                                        : <Button color='secondary' onClick={this.sendToQbo.bind(this)}
                                            disabled={this.state.unpostedInvoiceIds && this.state.unpostedInvoiceIds.length < 1}>Post to QuickBooks Online</Button>
                        }
                        {
                            this.state.postedInvoicesResult && // Validation errors: Missing accounts, tax codes, etc.
                            this.state.postedInvoicesResult.error &&
                            this.state.postedInvoicesResult.error.find &&
                            this.state.postedInvoicesResult.error.length > 0 &&
                            <div>
                                <strong>Errors:</strong>
                                <ul>
                                {
                                    this.state.postedInvoicesResult.error
                                        .map((jsonError, index) => (
                                            <li key={index}>{typeof jsonError === "string" ? jsonError : JSON.stringify(jsonError)}</li>
                                        ))
                                }
                                </ul>
                            </div>
                        }
                        {
                            this.state.postedInvoicesResult &&
                            this.state.postedInvoicesResult.error &&
                            !this.state.postedInvoicesResult.error.find && // Unknown exceptions: 500 request errors, failure to create customer, product, invoice etc.
                            <div>
                                <strong>Error:</strong>
                                <br />
                                {
                                    typeof this.state.postedInvoicesResult.error === "string"
                                        ? this.state.postedInvoicesResult.error
                                        : JSON.stringify(this.state.postedInvoicesResult.error)
                                }
                            </div>
                        }
                        {
                            this.state.unpostedInvoicesQueryResult &&
                            this.state.postedInvoicesResult && this.state.postedInvoicesResult.invoicesCreated &&
                            this.state.postedInvoicesResult.invoicesCreated.length > 0 &&
                            <div className="data-review-section">
                                Sent {this.state.postedInvoicesResult.invoicesCreated.length} invoice(s):
                                {
                                    this.state.postedInvoicesResult.invoicesCreated.map(this.getInvoiceReviewWithPdf, this)
                                }
                            </div>
                        }
                        {
                            this.state.unpostedInvoicesQueryResult &&
                            this.state.postedInvoicesResult && this.state.postedInvoicesResult.invoicesExistingInQuickbooks &&
                            this.state.postedInvoicesResult.invoicesExistingInQuickbooks.length > 0 &&
                            <div className="data-review-section">
                                Did not send {this.state.postedInvoicesResult.invoicesExistingInQuickbooks.length} invoice(s), because they already exist in QuickBooks Online:
                                {
                                    this.state.postedInvoicesResult.invoicesExistingInQuickbooks.map(this.getInvoiceDuplicateReviewWithPdf, this)
                                }
                            </div>
                        }
                        {
                            this.state.unpostedInvoicesQueryResult &&
                            this.state.postedInvoicesResult &&
                            this.state.postedInvoicesResult.creditMemosCreated &&
                            this.state.postedInvoicesResult.creditMemosCreated.length > 0 &&
                            <div className="data-review-section">
                                Sent {this.state.postedInvoicesResult.creditMemosCreated.length} credit memo(s):
                                {
                                    this.state.postedInvoicesResult.creditMemosCreated.map(this.getInvoiceReview, this)
                                }
                            </div>
                        }
                        {
                            this.state.unpostedInvoicesQueryResult &&
                            this.state.postedInvoicesResult && this.state.postedInvoicesResult.creditMemosExistingInQuickbooks &&
                            this.state.postedInvoicesResult.creditMemosExistingInQuickbooks.length > 0 &&
                            <div className="data-review-section">
                                Did not send {this.state.postedInvoicesResult.creditMemosExistingInQuickbooks.length} credit memo(s), because they already exist in QuickBooks Online:
                                {
                                    this.state.postedInvoicesResult.creditMemosExistingInQuickbooks.map(this.getInvoiceDuplicateReview, this)
                                }
                            </div>
                        }
                        </Col>
                    </PodBody>
                </Pod>
                <WaitSpinner shown={this.state.showWaitSpinner} />
            </Container>
        )
    }
}