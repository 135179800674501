import React, { Component } from 'react';
import './waitSpinner.css';

export default class WaitSpinner extends Component {
    render() {
        if (this.props.shown) {
            return (
                <div className="cw-loading" id="loading-dialog">
                    <div className="cw-loading-spinner cw-loading-spinner-child-outer">
                        <div className="cw-loading-spinner cw-loading-spinner-child-inner"></div>
                    </div>
                </div>
            );
        }
        return null;
    }
}