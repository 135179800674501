import jwt_decode from 'jwt-decode';
import AuthenticatedApiCaller from "./authenticated-api-caller";

export default class TokenStore {
    static getToken() {
        return this.getCookie('id_token');
    }

    static getCookie(cookie) {
        const name = `${cookie}=`;
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    static getDecodedToken() {
        return jwt_decode(this.getToken());
    }

    static isAdmin() {
        let token = this.getToken();
        if (!token) {
            return false;
        }
        return (jwt_decode(token).roles || []).find(x => x.toLowerCase() === 'ConnectWise Manage Integration for QuickBooks Online Admin'.toLowerCase());
    }

    static async signout() {
        let options = { method: 'GET' };
        if (AuthenticatedApiCaller.isLocal()) {
            options.mode = 'cors';
            options.credentials = 'include'; // Cookies aren't set cross domain by default.
        }
        let response = await fetch(AuthenticatedApiCaller.getUrl('sso-signout'), options);
        if (!response.ok) {
            let errorBody = await response.text();
            try { errorBody = JSON.parse(errorBody); }
            catch (e) { }
            throw errorBody || response.statusText;
        }
        const responseJson = await response.json();
        window.location = responseJson['sso-signout-url'];
    }

    static async goToAuthenticationRedirect(finalLocation) {
        finalLocation = finalLocation || window.location.hash;
        let response = await fetch(AuthenticatedApiCaller.getUrl('sso-domain'), { method: 'GET' });
        if (!response.ok) {
            let errorBody = await response.text();
            try { errorBody = JSON.parse(errorBody); }
            catch (e) { }
            throw errorBody || response.statusText;
        }
        const responseJson = await response.json();
        var redirectUri = AuthenticatedApiCaller.getUrl('sso-callback');
        if (!AuthenticatedApiCaller.isLocal()) {
            redirectUri = window.location.origin + redirectUri;
        }
        redirectUri = encodeURIComponent(redirectUri);
        var scopes = [
            'openid',
            'profile',
            'email',
            'roles',
            'api.productinstances',
            'api.accounting'
        ];
        window.location.href = `${responseJson.fqdn}/connect/authorize?`
            + `client_id=connectwise.qbo&response_type=id_token%20token&scope=${scopes.join('%20')}&redirect_uri=${redirectUri}&nonce=${responseJson.nonce}&response_mode=form_post`
            + `&state=${encodeURIComponent(finalLocation)}`;
    }

}