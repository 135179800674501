import React from 'react';
import './pod.css';

function Pod(props) {

    const { className, ...attributes } = props;

    return (
        <div className='pod' {...attributes} >
            {props.children}
        </div>
    );

}

function PodHeader(props) {
    const { className, ...attributes } = props;
    return (
        <div className='pod-header' {...attributes} >
            {props.children}
        </div>
    );

}

function PodBody(props) {
    const { className, ...attributes } = props;
    return (
        <div className='pod-body' {...attributes}>
            {props.children}
        </div>
    );

}


export { Pod, PodHeader, PodBody }