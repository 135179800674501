import React, { Component } from 'react';
import './pod.css';
import { Pod, PodHeader, PodBody } from './Pod';

export default class ErrorPod extends Component
{
render() {
    return (
        <Pod style= {{marginTop: '10px'}}>
        <PodHeader style= {{color: '#fff', backgroundColor: '#e96471'}}>{this.props.header}</PodHeader>
        <PodBody style= {{paddingLeft: '10px', paddingRight: '10px'}}>
            {this.props.body}
        </PodBody>
    </Pod> 
    );
}
}