import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Button
} from 'reactstrap';
import TokenStore from '../../Services/token-store';

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  logout() {
    TokenStore.signout();
  }

  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <span className="navbar-brand">ConnectWise Manage Integration for QuickBooks Online</span>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {this.props.isAdmin &&
              <NavItem>
                <NavLink to="/setup-qbo" className="nav-link">Set Up QuickBooks Online</NavLink>
              </NavItem>
              }
              {this.props.token &&
                <NavItem>
                  <NavLink to="/" className="nav-link" onClick={() => window.location.load("/")}>Run New Batch</NavLink>
                </NavItem>}
              {this.props.token &&
                <NavItem>
                  <Button onClick={this.logout}>Logout</Button>
                </NavItem>}
            </Nav>
          </Collapse >
        </Navbar >
      </div >
    );
  }
}